var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "TreePanelSelect" } }, [
    _c(
      "div",
      {
        staticStyle: { width: "164px" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            _vm.listVisible = !_vm.listVisible
          },
        },
      },
      [
        _c("CSSelect", [
          _vm.selectedName
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "130px",
                    "margin-left": "6px",
                    overflow: "hidden",
                    "white-space": "nowrap",
                    "text-overflow": "ellipsis",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.selectedName) + " ")]
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    width: "130px",
                    "margin-left": "6px",
                    "font-size": "15px",
                    color: "#999",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.tipsText) + " ")]
              ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.listVisible,
            expression: "listVisible",
          },
        ],
        staticClass: "tree-source-panel",
        style: { "--width": _vm.width },
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
          scroll: _vm.onScroll,
        },
      },
      [
        !_vm.hideDefaultOption
          ? _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.onClick("")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.tipsText))]
            )
          : _vm._e(),
        _vm._l(_vm.traverseData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "tree-item",
              on: {
                click: function ($event) {
                  return _vm.onClick(item[_vm.itemId], item[_vm.textName])
                },
              },
            },
            [_vm._v(_vm._s(item[_vm.textName]) + " ")]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }